import { IArialStyles, IRubikStyles, ITekoStyles } from "@schema/Styles";

export enum Colors {
    primary = "#0215A0",
    secondary = "#00e290",
    black = "#000000",
    white = "#FFFFFF",
    yellow = "#FFAA17",
    lightYellow = "#FFDA6B",
    red = "#DC3545",
    blue = "#3590F1",
    turquoise = "#20DBAB",
    peach = "#FFA56A",
    cream = "#FFFADF",
    hoveredBlue = "#2788EF",
    disabledBlue = "#2A68AB",
    grey = "#878787",
    lightGrey = "#CCCCCC",
    darkGrey = "#707070",
    lightGrey2 = "#B4B4B4",
    brown = "#4B4740",
    darkBg = "#2D2D2D",
    themeGreen = "#00E290",
    themeDarkBlue = "#0215A0",
}

export const ArialFonts: IArialStyles = {
    common: {
        fontFamily: "Arial, Helvetica, sans-serif",
        fontStyle: "normal",
        fontVariant: "normal",
    },
    regular: {
        fontWeight: 400,
    },
};

export const RubikFonts: IRubikStyles = {
    common: {
        fontFamily: "Rubik, Helvetica, serif",
        fontStyle: "normal",
        fontVariant: "normal",
    },
    light: {
        fontWeight: 300,
    },
    medium: {
        fontWeight: 500,
    },
    bold: {
        fontWeight: 700,
    },
};

export const TekoFonts: ITekoStyles = {
    common: {
        fontFamily: "Teko, Helvetica, sans-serif",
        fontStyle: "normal",
        fontVariant: "normal",
    },
    light: {
        fontWeight: 300,
    },
    medium: {
        fontWeight: 500,
    },
    semiBold: {
        fontWeight: 600,
    },
    bold: {
        fontWeight: 700,
    },
};

export const FontSizesInPx = {
    12: "12px",
    14: "14px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    27: "27px",
    28: "28px",
    32: "32px",
    40: "40px",
    80: "80px",
    100: "100px",
    120: "120px",
    127: "127px",
};

export const FontSizesInRem = {};
