import { Suspense, lazy } from "react";
import useCredentials from "@store/useCredentials";
import { Navigate, Route, Routes } from "react-router-dom";
import PageRoutes, { privateRoutes, publicRoutes } from "./PageRoutes";

const PageLayout = lazy(() => import('@pages/page_layout/PageLayout'));

// FIXME: Need to add fallback component
const IndexRouter = () => {
    const { userToken } = useCredentials();
    if (userToken)
        return (
            <PageLayout>
                <Suspense fallback={<h1>Loading...</h1>}>
                    <Routes>
                        <Route index element={<Navigate to={PageRoutes.DashboardPage} />} />
                        {privateRoutes.map(({ path, componentPath: PageComponent }) =>
                            <Route key={path} path={path} element={<PageComponent />} />
                        )}
                        <Route path="/*" element={<Navigate to={PageRoutes.DashboardPage} />} />
                    </Routes>
                </Suspense>
            </PageLayout>
        );
    return (
        <Suspense fallback={<h1>Loading...</h1>}>
            <Routes>
                <Route index element={<Navigate to={PageRoutes.SignInPage} />} />
                {publicRoutes.map(({ path, componentPath: PageComponent }) =>
                    <Route key={path} path={path} element={<PageComponent />} />
                )}
                <Route path="/*" element={<Navigate to={PageRoutes.SignInPage} />} />
            </Routes>
        </Suspense>
    );
};

export default IndexRouter;
