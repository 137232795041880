import { Toast } from 'primereact/toast';
import {RefObject} from 'react'
import create from "zustand";

interface IToast {
    toastRef: RefObject<Toast>|null;
    setToastRef: (toastRef: RefObject<Toast>) => void;
    clear: () => void;
}

const useToast = create<IToast>((set) => ({
    toastRef: null,
    setToastRef: (toastRef) => set(() => ({ toastRef })),
    clear: () =>
        set(() => ({
            toastRef: null,
        })),
}));

export default useToast;
