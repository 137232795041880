import { lazy } from "react";

enum PageRoutes {
    // Public Routes
    SignInPage = "/login",
    ForgotPasswordPage = "/forgot-password",
    ForgotPasswordOtpPage = "/forgot-password/otp",
    // Private Routes
    DashboardPage = "/dashboard",
    MembersPage = "/members",
    ProjectsPage = "/projects",
    WorkspacePage = "/workspace",
    CalendarPage = "/calendar",
    RolePage = "/roles",
    HolidayPage = "/holidays",
    SkillPage = "/skills",
    AnnouncementPage = "/announcements",
    PerformancePage = "/performances",
    ProposalPage = "/proposals",
    DesignationPage = "/designations",
    PresentationPage = "/presentations",
    SalarySlip = "/salary-slip",
}
export default PageRoutes;

export const publicRoutes = [
    {
        path: PageRoutes.ForgotPasswordPage,
        componentPath: lazy(() => import("@pages/general/ForgotPassword")),
    },
    {
        path: PageRoutes.ForgotPasswordOtpPage,
        componentPath: lazy(() => import("@pages/general/ForgotPasswordOtp")),
    },
    {
        path: PageRoutes.SignInPage,
        componentPath: lazy(() => import("@pages/general/SignIn")),
    },
];

export const privateRoutes = [
    {
        path: PageRoutes.DashboardPage,
        componentPath: lazy(() => import("@pages/private/dashboard/DashboardScreen")),
    },
    {
        path: PageRoutes.MembersPage,
        componentPath: lazy(() => import("@pages/private/member/MemberScreen")),
    },
    {
        path: PageRoutes.ProjectsPage,
        componentPath: lazy(() => import("@pages/private/project/ProjectScreen")),
    },
    {
        path: PageRoutes.RolePage,
        componentPath: lazy(() => import("@pages/private/role/RoleScreen")),
    },
    {
        path: PageRoutes.HolidayPage,
        componentPath: lazy(() => import("@pages/private/holiday/HolidayScreen")),
    },
    {
        path: PageRoutes.SkillPage,
        componentPath: lazy(() => import("@pages/private/skill/SkillScreen")),
    },
    {
        path: PageRoutes.AnnouncementPage,
        componentPath: lazy(() => import("@pages/private/announcement/AnnouncementScreen")),
    },
    {
        path: PageRoutes.WorkspacePage,
        componentPath: lazy(() => import("@pages/private/workspace/WorkspaceScreen")),
    },
    {
        path: PageRoutes.PerformancePage,
        componentPath: lazy(() => import("@pages/private/performance/PerformanceScreen")),
    },
    {
        path: PageRoutes.ProposalPage,
        componentPath: lazy(() => import("@pages/private/proposals/ProposalScreen")),
    },
    {
        path: PageRoutes.DesignationPage,
        componentPath: lazy(() => import("@pages/private/designation/DesignationScreen")),
    },
    {
        path: PageRoutes.PresentationPage,
        componentPath: lazy(() => import("@pages/private/presentation/PresentationScreen")),
    },
    {
        path: PageRoutes.CalendarPage,
        componentPath: lazy(() => import("@pages/private/calendar/CalendarScreen")),
    },
    {
        path: PageRoutes.SalarySlip,
        componentPath: lazy(() => import("@pages/private/salary-slip/SalarySlipScreen")),
    },
];
