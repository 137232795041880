import create from "zustand";

interface ICredentials {
    userId?: string;
    userToken?: string;
    userTokenType?: string;
    setUserId: (userId: string) => void;
    setUserToken: (userToken: string) => void;
    setUserTokenType: (userTokenType: string) => void;
    clear: () => void;
}

const useCredentials = create<ICredentials>((set) => {
    let userTokenStr: string | undefined;
    let userTokenTypeStr: string | undefined;
    const credentialsStr = localStorage.getItem('credentials');
    if (credentialsStr) {
        const credentials = JSON.parse(credentialsStr);
        if (credentials) {
            if (credentials.userToken) userTokenStr = credentials.userToken;
            if (credentials.userTokenType) userTokenTypeStr = credentials.userTokenType;
        }
    }
    return ({
        userToken: userTokenStr,
        userTokenType: userTokenTypeStr,
        setUserId: (userId: string) => set((state) => ({ ...state, userId })),
        setUserToken: (userToken: string) => set((state) => ({ ...state, userToken })),
        setUserTokenType: (userTokenType: string) => set((state) => ({ ...state, userTokenType })),
        clear: () =>
            set((state) => ({
                ...state,
                userId: undefined,
                userToken: undefined,
                userTokenType: undefined,
            })),
    })
});

export default useCredentials;
