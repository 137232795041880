import React from "react";
import IndexRouter from "@routes/Routes";
import CommonToast from "@components/CommonToast";
// Prime Css
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "@assets/css/style.css";

function App() {
    return (
        <div className="App">
            <IndexRouter />
            <CommonToast />
        </div>
    );
}

export default App;
