/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import useToast from "@store/useToast";
import styled from "styled-components";
import { FontSizesInPx } from "@common/styles";

const StyledToast = styled(Toast)`
    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        width: 350px;
        right: 10;
        // position: absolute;
    }

    .p-toast-summary {
        font-size: ${FontSizesInPx[20]};
    }
    .p-toast-detail {
        font-size: ${FontSizesInPx[18]};
    }
`;
const CommonToast = () => {
    const { setToastRef, clear } = useToast();
    const toastRef = useRef<Toast>(null);
    useEffect(() => {
        if (toastRef && toastRef?.current) setToastRef(toastRef);
        return clear;
    }, [toastRef]);
    return <StyledToast ref={toastRef} appendTo="self" />;
};
export default CommonToast;
